import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function RunAutoEvalTest(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>
          Dataster Documentation - Bring your own Azure OpenAI model
        </title>
        <meta
          name="description"
          content="Guide to running a RAG Auto Evaluation test"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/run-an-auto-eval-test/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick} id="top">
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>
                Run an Automated Evaluation Test
              </h2>
            </div>
            <div>
              <p>
                Dataster provides a robust automated evaluation framework that
                empowers builders to rigorously assess the quality of their
                GenAI applications' outputs across their entire use case. This
                framework can handle hundreds of prompts, sending them to
                various Large Language Models (LLMs) and Retrieval-Augmented
                Generation (RAG) systems.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account.</li>
                  <li>One or more user prompts grouped in a use case.</li>
                  <li>
                    To be included in an automated evaluation, the prompts must
                    have a ground truth.
                  </li>
                  <li>
                    One or more system prompts part of the same use case as the
                    user prompts.
                  </li>
                  <li>
                    One or more LLMs. Dataster provides off-the-shelf LLMs that
                    can be used for performance testing.
                  </li>
                  <li>Optionally, one or more RAGs.</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the Human Evaluation Page
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the Automated Evaluation page by clicking "Auto
                    Evaluation" in the left navigation pane.
                  </li>
                </ol>
              </p>

              <h3 style={{ margin: "1rem 0" }}>Step 2: Select User Prompts</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Select the use case to use for testing.</li>
                  <li>
                    The interface indicates how many user prompts have been
                    created for this use case.
                  </li>
                  <li>One use case must be selected.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/autoEvalJobInputs.png"
                alt="Select User Prompts"
                title="Select User Prompts"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Step 3: Select LLMs and RAGs</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Select the LLMs to use for testing.</li>
                  <li>Select the RAGs to use for testing.</li>
                  <li>At least one RAG or one LLM must be selected.</li>
                  <li>LLMs and RAGs are indicated by different icons.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobLLMsRAGs.png"
                alt="Select LLMs and RAGs"
                title="Select LLMs and RAGs"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>
                Step 4: Select System Prompts
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Select one or more system prompts for the use case.</li>
                  <li>At least one system prompt must be selected.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobSystemPrompts.png"
                alt="Select System Prompts"
                title="Select System Prompts"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>
                Step 5: Run the Automated Evaluation Job
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    The user interface indicates how many tests will be run.
                  </li>
                  <li>
                    Click <strong>Run</strong>.
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobRunJob.png"
                alt="Run the Auto Evaluation job"
                title="Run the Auto Evaluation job"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>
                Step 6: Automated Evaluation Job Execution
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>The user interface displays each test execution.</li>
                  <li>
                    Upon test completion, the output quality evaluation is
                    displayed (thumb up or down).
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/autoEvalJobRun.png"
                alt="Auto Evaluation job execution"
                title="Auto Evaluation job execution"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Step 7: Observe the Results</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    After all the tests are complete, the consolidated results
                    are displayed.
                  </li>
                  <li>
                    For each model and RAG, the average score is displayed.
                  </li>
                  <li>
                    For each system prompt, the average score is displayed.
                  </li>
                  <li>
                    For each combination of model, RAG, and system prompt, the
                    average score is displayed.
                  </li>
                  <li>Optionally, save the job results.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/autoEvalJobResult.png"
                alt="Auto Evaluation job results"
                title="Auto Evaluation job results"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully run an automated evaluation test in
                Dataster. This allows you to measure the performance of your use
                case and make informed decisions to optimize output quality.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
