import { Helmet } from "react-helmet";
import ShareButtons from "../ui/shareButtons";
import DatasterHelps from "../components/datasterHelps";
import NewsMonth from "../components/newsMonth/newsMonth";

export default function About(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster News - Latest Updates & Feature Announcements</title>
        <meta
          name="description"
          content="Stay informed with the latest news and updates on GenAIOps & LLMOps. Read about our software updates, new features, and capabilities in LLMOps & GenAIOps for AI developers."
        />
        <link rel="canonical" href="https://www.dataster.com/news/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>News Center</h1>
        <DatasterHelps />
        <NewsMonth month={"February 2025"}>
          <h2>
            Enhanced Human Evaluation Job Results: Visualize Output Quality on a
            Graph
          </h2>
          <div>
            <p>
              In line with our recent enhancements for latency jobs, we've also
              improved the visualization of human evaluation job results. Now,
              you can see each LLM, RAG, system prompt, and their combinations
              displayed on a bar chart, making it easier to assess output
              quality.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Enhanced Human Evaluation Job Results: Visualize Output Quality on a
            Graph"
            />
          </div>

          <h2>
            Enhanced Latency Job Results: Visualize Min, Median, Max, Q1, and Q3
            on a Graph
          </h2>
          <div>
            <p>
              We are excited to announce a new feature for Latency jobs! For
              each LLM, RAG, System prompt, and their combinations, we now
              provide a graph that plots the median latency, as well as the
              minimum, maximum, and first and third quartiles. We are confident
              that this enhancement will empower builders to make the best
              data-driven decisions for their Generative AI applications.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Enhanced Latency Job Results: Visualize Min, Median, Max, Q1, and Q3 on a Graph"
            />
          </div>
        </NewsMonth>

        <NewsMonth month={"January 2025"}>
          <h2>GPT-4o 2024-11-20 on Azure is now on Dataster</h2>
          <div>
            <p>
              We are thrilled to introduce a powerful new model to our catalog!
              With the addition of the latest GPT-4o variant, known as{" "}
              <code>gpt-4o-2024-11-20</code>, our users can now evaluate whether
              updating their version of GPT-4o will result in improvements or
              regressions for their specific use cases.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="GPT-4o 2024-11-20 on Azure is now on Dataster"
            />
          </div>
        </NewsMonth>

        <NewsMonth month={"December 2024"}>
          <h2>Amazon OpenSearch Serverless now available on Dataster</h2>
          <div>
            <p>
              We are excited to introduce another vector store option for our
              users to build RAG applications and evaluate their accuracy or
              latency. Previously, users could only build RAGs with grounding
              data stored in Azure AI Search. Now, with the addition of Amazon
              OpenSearch Serverless, we offer a compelling new option for RAG
              applications. You can now create a fully AWS-hosted RAG using
              Titan Text Embeddings V2, OpenSearch Serverless, and Claude 3.5.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Amazon OpenSearch Serverless now available on Dataster"
            />
          </div>

          <h2>Amazon Titan Text Embeddings V2 now available on Dataster</h2>
          <div>
            <p>
              To empower our users to build and test their RAGs entirely on AWS,
              we are thrilled to announce support for
              <code>amazon.titan-embed-text-v2:0</code>. Previously, only the
              latest OpenAI embedding models were supported. This update
              underscores our commitment to being truly multi-cloud and
              providing our users with a wide range of options for their GenAI
              applications.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Amazon Titan Text Embeddings V2 now available on Dataster"
            />
          </div>

          <h2>Prompt catalog update</h2>
          <div>
            <p>
              We are dedicated to creating the most versatile and feature-rich
              prompt gallery available. Now, you have the power to re-order your
              prompts, allowing you to present them in the sequence that best
              suits your needs, rather than the order they were created in.
              Experience the freedom to customize your workflow like never
              before!
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Prompt catalog update"
            />
          </div>

          <h2>Bias reduction and randomization</h2>
          <div>
            <p>
              In the realm of LLM and RAG output evaluation by humans,
              minimizing bias is crucial, if not eliminating it entirely. It's
              easy to develop a preference for a specific model or provider, but
              this can skew the objectivity of the evaluation process. Dataster
              offers robust bias removal mechanisms for human evaluation.
              Historically, it has been possible to mask the model, RAG, and
              even the system prompt used to generate a particular output. With
              today's release, we introduce an additional layer of bias
              reduction by randomizing the order in which input and output pairs
              are presented to evaluators. This makes it virtually impossible to
              determine which model, RAG, or system prompt produced the output
              until the entire evaluation is complete. Happy blind testing!
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Bias reduction and randomization"
            />
          </div>
        </NewsMonth>

        <NewsMonth month={"November 2024"}>
          <h2>Cohere Command R+ on AWS is now on Dataster</h2>
          <div>
            <p>
              We're continuing our Large Language Model catalog expansion with
              the addition of <code>cohere.command-r-plus-v1:0</code> as an AWS
              hosted option; bring your own or use ours. Sign in, send some
              tokens and try it out!
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Cohere Command R+ on AWS is now on Dataster"
            />
          </div>

          <h2>Jamba 1.5 Large on AWS is now on Dataster</h2>
          <div>
            <p>
              Last month, we introduced our first A21 Large Language Model with
              Jamba 1.5 Mini. Building on this momentum, we are excited to
              announce the addition of <code>ai21.jamba-1-5-large-v1:0</code> to
              our catalog! This model is now available as an AWS-hosted option.
              As always, you have the flexibility to bring your own AWS-hosted
              Jamba 1.5 Large if you prefer. Give it a try and see what it can
              do!
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Jamba 1.5 Large on AWS is now on Dataster"
            />
          </div>

          <h2>Bulk deletion of prompts</h2>
          <div>
            <p>
              For specific sensitive use cases, users may want to delete their
              prompts from the database. We're introducing a feature that allows
              for the easy deletion of a large collection of prompts from the
              catalog and the underlying data store, while retaining the ones
              you wish to keep. Deleting a prompt or bulk deleting prompts will
              effectively remove all text and associated metadata from our
              database instantly. However, prompt content may still appear in
              our secure logs for up to seven days.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Bulk deletion of prompts"
            />
          </div>
        </NewsMonth>

        <NewsMonth month={"October 2024"}>
          <h2>Jamba 1.5 Mini on AWS is now on Dataster</h2>
          <div>
            <p>
              Today, we're thrilled to unveil the latest addition to our model
              catalog: <code>ai21.jamba-1-5-mini-v1:0</code>! This cutting-edge
              language model is now available as an AWS-hosted option. Plus, you
              still have the flexibility to bring your own AWS-hosted Jamba 1.5
              Mini if you prefer. Dive in and explore the possibilities!
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Jamba 1.5 Mini on AWS is now on Dataster"
            />
          </div>

          <h2>OpenAI GPT-4o-mini is now on Dataster</h2>
          <div>
            <p>
              We're excited to announce the addition of another powerful
              language model to our collection:{" "}
              <code>gpt-4o-mini-2024-07-18</code>. As always, you have the
              flexibility to bring your own OpenAI GPT-4o mini if you prefer.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="OpenAI GPT-4o-mini is now on Dataster"
            />
          </div>

          <h2>Language Models and RAG Auto evaluation</h2>
          <div>
            <p>
              Dataster has a human evaluation feature where users can rate a
              list of completions as good or bad, or alternatively, on a scale
              from 1 to 5. We are excited to release a new capability in beta
              that automates the binary evaluation process. Dataster can now
              automatically rate all your candidate models and RAGs against your
              use case, helping you make the best decisions when building your
              GenAI-backed application.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="Language Models and RAG Auto evaluation"
            />
          </div>

          <h2>User Prompt and Ground Truth</h2>
          <div>
            <p>
              We are adding the capability to optionally save a ground truth
              answer corresponding to a user question in a user prompt. This
              will underpin our upcoming Auto Eval feature, which will allow you
              to launch automated evaluation jobs to determine how your RAGs
              perform against a large and representative set of user questions.
              This will supplement the existing Human Eval feature, which lets
              users manually assign grades to each response and rank their
              models and RAGs for a specific use case.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="User Prompt and Ground Truth"
            />
          </div>

          <h2>OpenAI on Dataster</h2>
          <div>
            <p>
              We are adding support for <code>gpt-4o-2024-08-06</code> hosted on
              OpenAI. This model was previously supported on Dataster only as an
              Azure OpenAI hosted model. Users can now compare the model’s
              performance across both providers. With this release, Dataster now
              supports 10 models across three different providers.
            </p>
          </div>
          <div className="share">
            <ShareButtons
              url="www.dataster.com/news/"
              title="OpenAI on Dataster"
            />
          </div>
        </NewsMonth>
      </div>
    </>
  );
}
